import { render, staticRenderFns } from "./DataViewSidebarapprovisionnementType.vue?vue&type=template&id=efd14906&scoped=true&"
import script from "./DataViewSidebarapprovisionnementType.vue?vue&type=script&lang=js&"
export * from "./DataViewSidebarapprovisionnementType.vue?vue&type=script&lang=js&"
import style0 from "./DataViewSidebarapprovisionnementType.vue?vue&type=style&index=0&id=efd14906&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "efd14906",
  null
  
)

export default component.exports